<template>

  <ion-page>
    <div class="container" v-if="!paymentIsExpired">
      <ion-spinner name="dots"></ion-spinner>
    </div>
    <div class="container" v-if="paymentIsExpired">
      <strong class="capitalize">Deine Frist ist abgelaufen</strong>
      <p style="margin-top: 10px;">Du hast leider zu lange gebraucht um zu bezahlen. </p>

      <ion-button size="large" class="topMargins sideMargins" style="margin-top: 20px;" expand="block" color="primary" router-link="/profile/entries">Zu deinen Verlosungen</ion-button>
    </div>
  </ion-page>
</template>

<script>
import {
    IonPage,
    IonSpinner,
    IonButton
} from '@ionic/vue';

import axios from "axios";
import moment from "moment";

export default {
  name: "checkPayment",
  components: {
    IonPage,
    IonSpinner,
    IonButton
  },
  data() {
    return {
      payment: null,
      paymentIsExpired: false
    }
  },
  methods: {
    checkEndDate(date){
      return moment(date).isAfter();
    },
  },
  created() {
    // check if user is logged in correctly
    if (!localStorage.userToken || !localStorage.userId || !localStorage.refreshToken){
        this.$router.push('/login');
    }
    // get payment data
    const paymentId = this.$route.params.id;
    axios.get(process.env.VUE_APP_API_URL + '/payments/' + paymentId)
        .then(result => {
          this.payment = result.data;
          console.log(result)
        }).finally(() => {
          // check if payment due at is not exceeded
          console.log(this.payment);
          if (!this.checkEndDate(this.payment.dueAt)){
            this.paymentIsExpired = true;
          }

          // redirect to payment page
          this.$router.push({ path: '/make-payment/' + this.$route.params.id});
        });
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}
</style>